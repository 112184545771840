import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../components/Header'
import AnimatedBackground from '../components/AnimatedBackground'

import './styles/fonts.scss'
import './styles/base.scss'
import './styles/variables.scss'
import './styles/layout.scss'
import './styles/buttons.scss'
import './styles/typo.scss'
import './styles/backgrounds.scss'

const myStaticQuery = graphql`
query layoutFullQuery {
  allContentfulTag: allContentfulTag {
    edges {
      node {
        items {
          ... on ContentfulLessons {
            slug
          }
          ... on ContentfulMediaLibrary {
            slug
          }
          ... on ContentfulLibrary {
            slug
          }
          ... on ContentfulNko {
            slug
          }
          ... on ContentfulVideoLibrary {
            slug
          }
        }
        title
        displayTitle
      }
    }
  }
  about: contentfulAbout {
    slug
    title
  }
  grass: file(relativePath: { eq: "grass.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 4400) {
        aspectRatio
        base64
        src
        sizes
        srcSet
        srcWebp
        srcSetWebp
      }
    }
  }
  fullBg: file(relativePath: { eq: "fullBg.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 4400) {
        aspectRatio
        base64
        src
        sizes
        srcSet
        srcWebp
        srcSetWebp
      }
    }
  }
}
`

const TemplateWrapper = ({ currentSlug, children }) => {
  return (
  <StaticQuery
    query={`${myStaticQuery}`}
    render={props => {
      const { grass, fullBg, allContentfulTag, about} = props
      return (
        <section className="l-section l-section--full-width">
          <AnimatedBackground
            fullBg={fullBg}
            grass={grass}
          />
          <Header allContentfulTag={allContentfulTag} about={about} slug={currentSlug} />
          {children}
        </section>
      )
    }}
  />
  )
}

export default TemplateWrapper
