import React from 'react'
import './QuizResult.scss'
import GatsbyLink from 'gatsby-link'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

// eslint-disable-next-line
import RestartIMG, {
  ReactComponent as RestartArrow,
} from '../../../images/arrow-reverse.svg'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  VKShareButton,
  VKIcon,
} from 'react-share'
const QuizResult = props => {
  const {
    result,
    lessonLink,
    libraryLink,
    lessonLinkTitle,
    libraryLinkTitle,
    videoLibraryLinkTitile,
    videoLibraryLink,
    linkedLessons,
    points,
    restart,
    winnerVideo,
    resultImage,
    imageTextForWinner,
    imageTextForMiddle,
    imageTextForLooser,
    resultTextWinner,
    resultTextMiddle,
    resultTextLooser,
    handleResultImageLoaded,
    itemImages
  } = props

  const getResultImageText = () => {
    let resultImageText = {
      hight: imageTextForWinner,
      middle: imageTextForMiddle,
      looser: imageTextForLooser,
    }
    return resultImageText[result] && resultImageText[result]
        .replace(
          ' {result} ',
          `<br/><span>${Math.floor(points)}</span><br/>`
        )
        .replace('баллов', getNumEnding(Math.floor(points)))
  }

  const getResultShareText = () => {
    let resultImageText = {
      hight: imageTextForWinner,
      middle: imageTextForMiddle,
      looser: imageTextForLooser,
    }
    return resultImageText[result] && resultImageText[result]
      .replace('{result}', Math.floor(points))
      .replace('баллов', getNumEnding(Math.floor(points)))
  }

  const getNumEnding = (iNumber) => {
    let sEnding, i;
    const aEndings = ['балл', 'балла', 'баллов']
    iNumber = iNumber % 100;
    if (iNumber >= 11 && iNumber <= 19) {
      sEnding = aEndings[2];
    }
    else {
      i = iNumber % 10;
      switch (i) {
        case (1): sEnding = aEndings[0]; break;
        case (2):
        case (3):
        case (4): sEnding = aEndings[1]; break;
        default: sEnding = aEndings[2];
      }
    }
    return sEnding;
  }

  return <div className="b-quiz-result">
      <div id="to-canvas" className="b-quiz-result__card b-quiz-result-card b-quiz-result-card--image">
        <Img fluid={resultImage.fluid} onLoad={() => handleResultImageLoaded(true)} />
        <div className="b-quiz-result-card__wrapper">
          <p className="b-quiz-result-card__title" dangerouslySetInnerHTML={{ __html: getResultImageText().trim() }} />
        </div>
      </div>
      <div className="b-quiz-result__social-block b-social-block">
        <span className="b-social-block__title">Поделиться</span>
        <FacebookShareButton url={window.location.href} quote={getResultShareText()}>
          <FacebookIcon size={32} />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href} title={getResultShareText()}>
          <TwitterIcon size={32} />
        </TwitterShareButton>
        <VKShareButton url={window.location.href} title={getResultShareText()}>
          <VKIcon size={32} />
        </VKShareButton>
        {/* <a
          target="_blank"
          className="b-social-block__link b-social-block__link--facebook"
          href={`https://facebook.com/sharer/sharer.php?u=${pageUrl}`}
        >
        </a> */}
      </div>

      {result === 'hight' && <div className="b-quiz-prize b-quiz-prize--video">
          <div className="b-quiz-prize__video" dangerouslySetInnerHTML={{ __html: winnerVideo }} />
          <p className="b-quiz-prize__title">{resultTextWinner}</p>
            {videoLibraryLink && <GatsbyLink className="b-button b-button--primary" to={`/${videoLibraryLink.slug}`}>
              {videoLibraryLinkTitile}
            </GatsbyLink>}
          <h2 className="t-header-quiz">Смотри еще:</h2>
          <div className="b-quiz-prize__others">
          {lessonLink && itemImages.images.map((image, key) => image.lessonLink.slug !== linkedLessons.slug && <div className="b-quiz-prize__other-item" key={key}>
              <Link className={`b-mainpage__link`} to={`/${image.lessonLink.slug}`} title={image.lessonLink.title}>
                <Img className={`b-mainpage__image`} fluid={image.image.fluid} alt={image.lessonLink.title}/>
                <p className="b-quiz-prize__other-item-header t-header-2">
                  {image.lessonLink.title}
                </p>
              </Link>
            </div>)}
          </div>
        </div>}
      {result !== 'hight' && <div className="b-quiz-prize">
          <div className="b-quiz-prize__wrapper">
            <p className="b-quiz-prize__title">
              {result === 'middle' ? resultTextMiddle : resultTextLooser}
            </p>
            <div className="b-quiz-prize__buttons-wrapper">
              <button className="b-button b-button--primary b-button--icon-margin" onClick={restart}>
                Пройти еще раз <RestartArrow />
              </button>
              {result === 'middle' && lessonLink && <GatsbyLink className="b-button b-button--primary" to={`/${lessonLink.slug}`}>
                  {lessonLinkTitle}
                </GatsbyLink>}
              {result === 'looser' && libraryLink && <GatsbyLink className="b-button b-button--primary" to={`/${libraryLink.slug}`}>
                  {libraryLinkTitle}
                </GatsbyLink>}
            </div>
          </div>
        </div>}
    </div>
}

QuizResult.propTypes = {
  // result: PropTypes.string,
  // lessonLink: PropTypes.string,
  // library: PropTypes.string,
  // points: PropTypes.number,
  // restart: PropTypes.fun,
  // winnerVideo: PropTypes.string,
  // resultImage: PropTypes.object,
  // imageTextForWinner: PropTypes.string,
  // imageTextForMiddle: PropTypes.string,
  // imageTextForLooser: PropTypes.string,
  // resultTextWinner: PropTypes.string,
  // resultTextMiddle: PropTypes.string,
  // resultTextLooser: PropTypes.string,
}

export default QuizResult
