import React from 'react'
import './tests.scss'
import { graphql } from 'gatsby'
import Quiz from '../components/Quiz'
import Layout from '../layouts'
import Seo from '../components/Seo'

const quizTemplate = props => {
  const library = props.data.library
  const { title, slug, description, image } = props.data.quiz
  const seo = {
    title,
    slug,
    description,
    image,
  }
  return (
    <Layout currentSlug={'/' + slug}>
      <Seo {...seo} />
      <section className="l-section l-section--full-width">
        <Quiz
          quiz={props.data.quiz}
          library={library}
          pagrUrl={slug}
          description={seo.description}
          itemImages={props.data.images}
        />
      </section>
      {/* <Footer /> */}
    </Layout>
  )
}

export default quizTemplate

export const pageQuery = graphql`
  query quizQuery($id: String!) {
    images: contentfulMainPage {
      title
      description
      image {
        file {
          url
        }
      }
      images {
        title
        lessonLink {
          slug
          title
        }
        image {
          id
          file {
            url
            fileName
            contentType
          }
          fluid(maxWidth: 641) {
            aspectRatio
            base64
            src
            sizes
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
    quiz: contentfulTests(id: { eq: $id }) {
      title
      description
      slug
      image {
        file {
          url
        }
      }
      questions {
        id
        question {
          childMarkdownRemark {
            html
          }
        }
        firstAnswer
        secondAnswer
        thirdAnswer
        fourthAnswer
      }
      resultImage {
        id
        file {
          url
          fileName
          contentType
        }
        fluid {
          aspectRatio
          base64
          src
          sizes
          srcSet
          srcWebp
          srcSetWebp
        }
      }
      imageTextForWinner
      imageTextForMiddle
      imageTextForLooser
      resultTextWinner
      resultTextMiddle
      resultTextLooser
      winnerVideo
      libraryLink {
        slug
        title
      }
      libraryLinkTitle
      lessonLink {
        slug
        title
      }
      lessonLinkTitle
      videoLibraryLink{
        slug
        title
      }
      videoLibraryLinkTitile
      linkedLessons {
        slug
      }
    }
  }
`
