import React from 'react'
// import PropTypes from 'prop-types'
import './QuizCard.scss'
import QuizAnswers from '../QuizAnswers'
import cn from 'classnames'

const QuizCard = props => {
  const {
    question,
    firstAnswer,
    secondAnswer,
    thirdAnswer,
    fourthAnswer,
  } = props.question
  const { quizStep, totalQuestions, tabIndex, isActive } = props
  const answers = [firstAnswer, secondAnswer, thirdAnswer, fourthAnswer]

  return (
    <div
      className={cn('b-quiz__card b-quiz-card', {
        'b-quiz-card--active': isActive,
      })}
    >
      <div className="b-quiz-card-question">
        <div className="b-quiz-card-question__wrapper">
          <span className="b-quiz-card-question__counter">
            {quizStep === totalQuestions ? totalQuestions : quizStep} /{' '}
            {totalQuestions}
          </span>
          <h2
            className="b-quiz-card-question__title t-header t-header--2"
            dangerouslySetInnerHTML={{
              __html: question.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
      <div className="b-quiz-card__footer">
        <QuizAnswers
          answers={answers}
          handleAnswer={props.handleAnswer}
          tabIndex={tabIndex}
        />
      </div>
    </div>
  )
}

// QuizCard.propTypes = {}

export default QuizCard
