import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import './Quiz.scss'
import QuizCard from './QuizCard'
import QuizResult from './QuizResult'

const CARD_TIME_OUT = 2000

const RESULT_REITING = {
  0: 20,
  1: 60,
}

class Quiz extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // quizStep: this.props.quiz.questions.length,
      // amountCorrectAnswers: this.props.quiz.questions.length - 5,
      quizStep: 0,
      amountCorrectAnswers: 0,
      cardWidth: 0,
      isResaultImageLoaded: false,
      pageUrl: false,
      resultText: '',
    }
  }
  componentDidMount() {
    // let result = prompt("введите колличество правельных ответов(если веести ноль ничего не произойдет)");
    // if (result > 0) {
    //   this.setState({
    //     quizStep: this.props.quiz.questions.length,
    //     amountCorrectAnswers: result,
    //   })
    // }
    this.setState({
      cardWidth: this.container.offsetWidth,
      pageUrl: window.location.href,
    })
  }
  handleResultImageLoaded(isResaultImageLoaded) {
    this.setState({ isResaultImageLoaded: isResaultImageLoaded })
  }
  handleResult(e) {
    if (e.key === "Enter") {
      this.setState({ quizStep: this.props.quiz.questions.length, amountCorrectAnswers: e.target.value})
    }
  }
  handleAnswer(isAnswerCorrect) {
    isAnswerCorrect &&
      this.setState({
        amountCorrectAnswers: this.state.amountCorrectAnswers + 1,
      })

    setTimeout(() => {
      this.setState({
        quizStep: this.state.quizStep + 1,
      })
    }, CARD_TIME_OUT)
  }
  handleRestartQuizz() {
    this.setState({
      quizStep: 0,
      amountCorrectAnswers: 0,
    })
  }
  getResultText = text => {
    this.setState({ resultText: text })
  }
  render() {
    const { title, questions } = this.props.quiz
    const {
      amountCorrectAnswers,
      quizStep,
      cardWidth,
      isResaultImageLoaded,
    } = this.state
    const totalQuestions = questions.length
    let result

    const getResult = () => {
      let resultPoints = (100 / totalQuestions) * amountCorrectAnswers
      if (resultPoints > RESULT_REITING[1]) {
        result = 'hight'
      } else if (
        resultPoints > RESULT_REITING[0] &&
        resultPoints < RESULT_REITING[1]
      ) {
        result = 'middle'
      } else {
        result = 'looser'
      }
      return result
    }

    return (
      <div className="l-container l-container--card-width">
        <div className="b-quiz">
          <h1 className="b-quiz__header t-header t-header--1">{title}</h1>
          <div
            className="b-quiz__overflow-container"
            ref={node => {
              this.container = node
            }}
          >
            <div
              className="b-quiz__slider"
              style={{ transform: `translateX(${-cardWidth * quizStep}px)` }}
            >
              {questions.map((question, key) => (
                <QuizCard
                  tabIndex={key === quizStep ? '0' : '-1'}
                  isActive={key === quizStep}
                  question={question}
                  quizStep={quizStep + 1}
                  totalQuestions={totalQuestions}
                  handleAnswer={answer => this.handleAnswer(answer)}
                  key={'QP' + question.id}
                />
              ))}
              {quizStep === totalQuestions && (
                <QuizResult
                  result={getResult()}
                  getResultText={this.getResultText}
                  description={this.props.description}
                  changeDescription={this.props.changeDescription}
                  library={this.props.library}
                  points={(100 / totalQuestions) * amountCorrectAnswers}
                  restart={() => this.handleRestartQuizz()}
                  isResaultImageLoaded={isResaultImageLoaded}
                  handleResultImageLoaded={isResaultImageLoaded =>
                    this.handleResultImageLoaded(isResaultImageLoaded)
                  }
                  itemImages={this.props.itemImages}
                  {...this.props.quiz}
                />
              )}
            </div>
          </div>
        </div>
        {/* <input
          type="text"
          onKeyPress={e => this.handleResult(e)}
          className="add-result"
        /> */}
      </div>
    )
  }
}

export default Quiz
