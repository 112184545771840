import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import cn from 'classnames'
import './QuizAnswers.scss'

class QuizAnswers extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isAnswered: false,
      isAnswerCorrect: false,
    }
  }

  handleClick(answer) {
    let isAnswerCorrect = this.isAnswerCorrect(answer)
    this.setState({
      isAnswered: true,
      isAnswerCorrect: isAnswerCorrect,
    })
    this.props.handleAnswer(isAnswerCorrect)
    setTimeout(() => {
      this.setState({
        isAnswered: false,
        isAnswerCorrect: false,
      })
    }, 2500)
  }

  isAnswerCorrect(answer) {
    return answer.trim().charAt(0) === '+'
  }

  render() {
    const answers = this.props.answers

    const { isAnswered } = this.state

    const trellingPlus = answer => {
      answer.trim().charAt(0) === '+' && (answer = answer.trim().substr(1))
      return answer
    }

    return (
      <div
        className={cn('b-quiz-answers', {
          'b-quiz-answers--disabled': isAnswered,
        })}
      >
        <ul className="b-quiz-answers h-list-unstyled">
          {answers.map(
            (answer, key) =>
              answer && (
                <li className="b-quiz-answers__answer" key={key}>
                  <button
                    tabIndex={this.props.tabIndex}
                    className={cn('b-button b-button--answer', {
                      'b-button--success':
                        isAnswered && this.isAnswerCorrect(answer),
                      'b-button--error':
                        isAnswered && !this.isAnswerCorrect(answer),
                    })}
                    onClick={() => this.handleClick(answer)}
                  >
                    {trellingPlus(answer)}
                    {/* {answer} */}
                  </button>
                </li>
              )
          )}
        </ul>
      </div>
    )
  }
}
QuizAnswers.propTypes = {}

export default QuizAnswers
