import React, { PureComponent } from 'react'
import Img from 'gatsby-image'
import './AnimatedBackground.scss'
import cn from 'classnames'
export default class AnimatedBackground extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isImageShouldBlink: true
    }
  }
  
  
  render() {
    const { fullBg } = this.props
    return (
      <div className="b-background__wrapper">
          <Img
            className={cn("b-background", {
              'b-background--blink' : this.state.isImageShouldBlink
            })}
            durationFadeIn={1000}
            style={{ position: 'absolute' }}
            fluid={fullBg.childImageSharp.fluid}
            onLoad={() => {this.setState({isImageShouldBlink: false})}}
          />
      </div>
    )
  }
}
